import { Box } from '@material-ui/core'
import Form from './form'
import RunCompetition from './run-competition'

export default function RunCompetitionContent() {
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        sx={{ marginTop: '0.1rem' }}
      />
      <RunCompetition />
      <Form />
    </>
  )
}
