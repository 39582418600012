import { useState } from 'react'
import {
  Box,
  Button,
  Grid,
  styled,
  TextField,
  Typography,
} from '@material-ui/core'
import { toast } from 'react-toastify'
import axios from 'axios'

const FormContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1200)]: {
        paddingLeft: '3rem',
        paddingRight: '3rem',
        marginTop: '0',
        boxShadow: 'none',
      },
    }
  }}
  margin: auto;
  margin-top: 3rem;
  margin-bottom: 3rem;
  max-width: 700px;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  background: #f5f6fa;
  padding: 1rem;
`

const StyledTypography = styled(Typography)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1200)]: {
        textAlign: 'center',
        marginBottom: '1.2rem',
      },
      [theme.breakpoints.down(900)]: {
        marginBottom: '0',
      },
    }
  }}
  font-weight: bolder;
  font-size: 1.5rem;
  color: #3c3835;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
  text-align: left;
`

export const FormContent = styled('div')`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(900)]: {
        flexDirection: 'column',
        rowGap: '2.5rem',
        marginTop: '2.4rem',
        marginBottom: '0',
      },
    }
  }}
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 2rem;
  margin-bottom: 1.5rem;
`

export const StyledTextField = styled(TextField)`
  width: 100%;
`
export const StyledButton = styled(Button)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(900)]: {
        width: '100%',
        marginBottom: '0',
      },
    }
  }}
  margin-bottom: 1rem;
`

export default function Form() {
  const [isLoading, setIsLoading] = useState(false)
  const [formData, setformData] = useState({
    type: 1,
    name: '',
    email: '',
    postcode: '',
    club: '',
    position: '',
    age: '',
    experience: '',
  })

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target
    if (e.target.name === 'postcode' || e.target.name === 'experience') {
      const onlyNums = e.target.value.replace(/[^0-9]/g, '')
      if (onlyNums.length < 10) {
        value = onlyNums
      }
    } else if (e.target.name === 'age') {
      const onlyNums = e.target.value.replace(/[^0-9]/g, '')
      if (onlyNums.length < 10) {
        value = onlyNums
      }
    }

    setformData({
      ...formData,
      [e.target.name]: value,
    })
  }

  const isValid = () => {
    const re = /\S+@\S+\.\S+/

    if (
      !formData.name ||
      !formData.email ||
      !formData.postcode ||
      !formData.club ||
      !formData.position
    ) {
      return false
    }

    if (!re.test(formData.email)) {
      toast.warning('Email address is not a valid format')
      return false
    }

    return true
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    setIsLoading(true)
    if (isValid() && !isLoading) {
      try {
        await axios.post(`https://api.tritagrugby.com/eois`, formData, {
          headers: {
            'Content-Type': 'application/json',
          },
        })
        setIsLoading(false)
        setformData({
          type: 1,
          name: '',
          email: '',
          postcode: '',
          club: '',
          position: '',
          age: '',
          experience: '',
        })
        toast.success('Form submitted successfully.')
      } catch (error) {
        setIsLoading(false)
        toast.error('Something went wrong, Please try again')
      }
    }
  }

  return (
    <FormContainer component="form" onSubmit={handleSubmit}>
      <StyledTypography>Enquire Now</StyledTypography>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} sm={12} md={12}>
          <FormContent>
            <StyledTextField
              InputProps={{ disableUnderline: true }}
              inputProps={{ tabIndex: 1 }}
              id="filled-basic"
              label="Name"
              variant="filled"
              size="small"
              name="name"
              onChange={handleOnChange}
              value={formData.name}
              required
            />
            <StyledTextField
              InputProps={{ disableUnderline: true }}
              inputProps={{ tabIndex: 1 }}
              type="email"
              id="filled-basic"
              label="Email"
              variant="filled"
              size="small"
              name="email"
              onChange={handleOnChange}
              value={formData.email}
              required
            />
          </FormContent>
          <FormContent>
            <StyledTextField
              InputProps={{ disableUnderline: true }}
              inputProps={{ tabIndex: 1 }}
              id="filled-basic"
              label="Postcode"
              variant="filled"
              size="small"
              name="postcode"
              onChange={handleOnChange}
              value={formData.postcode}
              required
            />
            <StyledTextField
              InputProps={{ disableUnderline: true }}
              inputProps={{ tabIndex: 1 }}
              id="filled-basic"
              label="Club name"
              variant="filled"
              size="small"
              name="club"
              onChange={handleOnChange}
              value={formData.club}
              required
            />
          </FormContent>
          <FormContent>
            <StyledTextField
              InputProps={{ disableUnderline: true }}
              inputProps={{ tabIndex: 1 }}
              id="filled-basic"
              label="Position held at club"
              variant="filled"
              size="small"
              name="position"
              onChange={handleOnChange}
              value={formData.position}
              required
            />
            <Box sx={{ width: '100%' }} />
          </FormContent>
          <Box display="flex" justifyContent="center">
            <StyledButton
              type="submit"
              variant="contained"
              color="primary"
              className="montserrat"
              disabled={isLoading}
            >
              Enquire
            </StyledButton>
          </Box>
        </Grid>
      </Grid>
    </FormContainer>
  )
}
