import { Box, Button, styled, Typography } from '@material-ui/core'
import { Link } from 'gatsby'
import RunCompetitionImg from '../../images/Competition/image_720.png'

const RunCompetitionContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1200)]: {
        flexDirection: 'column-reverse',
      },
    }
  }}
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
`
const StyledRunCompetitionImg = styled('img')`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1700)]: {
        height: '600px',
      },
      [theme.breakpoints.down(1600)]: {
        height: '600px',
      },
      [theme.breakpoints.down(1400)]: {
        height: '600px',
      },
      [theme.breakpoints.down(1230)]: {
        height: '400px',
      },
      [theme.breakpoints.down(1200)]: {
        height: '320px',
        marginLeft: '1rem',
      },
    }
  }}
  height: 600px;
  object-fit: cover;
`
const RunCompetitionTextContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1700)]: {
        marginLeft: '6.5%',
      },
      [theme.breakpoints.down(1400)]: {
        marginLeft: '6%',
      },
      [theme.breakpoints.down(1230)]: {
        marginLeft: '6%',
      },
      [theme.breakpoints.down(1200)]: {
        margin: 'auto',
        marginLeft: '1rem',
        width: '95%',
      },
    }
  }}
  margin-top: 2rem;
  margin-right: 1rem;
  margin-left: 9%;
  width: 80%;
`
const RunCompetitionTypography = styled(Typography)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1700)]: {
        fontSize: '1.1rem',
      },
      [theme.breakpoints.down(1650)]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.down(1600)]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.down(1600)]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.down(1400)]: {
        fontSize: '0.9rem',
      },
      [theme.breakpoints.down(1230)]: {
        fontSize: '0.8rem',
      },
      [theme.breakpoints.down(1200)]: {
        textAlign: 'left',
        fontSize: '0.9rem',
        marginLeft: '1rem',
        marginRight: '1rem',
      },
    }
  }}

  font-size: 1.2rem;
  color: #3c3835;
  margin-bottom: 2rem;
`
const RunCompetitionLabelTypography = styled(Typography)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1652)]: {
        fontSize: '1.5rem',
      },
      [theme.breakpoints.down(1200)]: {
        textAlign: 'left',
        fontSize: '1.4rem',
        marginLeft: '1rem',
        marginRight: '1rem',
      },
    }
  }}
  margin-top: 1rem;
  font-weight: bold;
  font-size: 1.5rem;
  color: #008174;
  margin-bottom: 2rem;
`

const StyledButton = styled(Button)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1424)]: {
        fontSize: '0.85rem',
      },
    }
  }}
  &:hover {
    border: 2px solid #f79b4c;
    background: #f79b4c;
  }
  border: 2px solid #f58220;
  color: #fff;
  margin-right: 1.5rem;
  text-transform: none;
  font-size: 0.9rem;
  background: #f58220;
`

export default function RunCompetition() {
  return (
    <RunCompetitionContainer>
      <RunCompetitionTextContainer>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginTop: '1rem',
            marginBottom: '2rem',
          }}
        >
          <RunCompetitionLabelTypography sx={{ margin: 0 }}>
            Run a Competition
          </RunCompetitionLabelTypography>
          <Link to="/register-association">
            <StyledButton variant="outlined">
              Onboard your club now
            </StyledButton>
          </Link>
        </div>
        <RunCompetitionTypography>
          TTR takes away any operational burden via its tech platform, allowing
          you to easily organise, manage and conduct your competitions. It also
          provides an instant and easy way to communicate with your
          participants.
        </RunCompetitionTypography>
        <RunCompetitionTypography>
          It is equally as easy and convenient for Participants, who can
          register, design and order their uniform, make payment and receive
          information, all from the palm of their hand.
        </RunCompetitionTypography>
        <RunCompetitionTypography>
          Getting ready to run Tri Tag is complete with three simple steps.
          <ol>
            <li>Onboard your club</li>
            <li>Open registration</li>
            <li>Kick off</li>
          </ol>
        </RunCompetitionTypography>
        <RunCompetitionTypography>
          Tri Tag playing apparel is ordered during the registration process and
          delivered to you prior to kick off. We even cover insurances.
        </RunCompetitionTypography>

        <RunCompetitionTypography>
          If you are interested in conducting competitions of Tri Tag Rugby
          please enquire now.
        </RunCompetitionTypography>
      </RunCompetitionTextContainer>

      <StyledRunCompetitionImg
        src={RunCompetitionImg}
        alt="logo"
        placeholder="blurred"
      />
    </RunCompetitionContainer>
  )
}
